import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import BenefitsOfDaye from './HomeBenefits';
import Landing from './HomeLanding';
import SideEffects from './HomeSideEffects';
import IntroToQuestionaire from './HomeIntroToQuestionaire';
import Rumours from './HomeRumours';
import Subscription from './HomeSubscription';
import CREInfo from './HomeCREInfo';
import MedicalAdvisoryBoard from './HomeMedicalAdvisoryBoard';
import Footer from './Footer';
import '../sass/main.scss';
import 'animate.css/animate.min.css';

export default function Home() {
  return (
    <>
      <GatsbySeo
        title="Daye Contruception"
        description="We're on a mission to close the gender pain gap. Join us"
      />
      <ScrollAnimation animateIn="fadeIn">
        <PageContainer>
          <Landing />
          <IntroToQuestionaire />
          <Subscription />
          <BenefitsOfDaye />
          <SideEffects />
          <Rumours />
          <CREInfo />
          <MedicalAdvisoryBoard />
          <Footer />
        </PageContainer>
      </ScrollAnimation>
    </>
  );
}

const PageContainer = styled.div`
  margin: auto;
  animation: fadein 0.8s;
`;
